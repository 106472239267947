// FileName: Navb.js 

import React, { useState } from "react"; 

const Nav = ({ setLibraryStatus, libraryStatus }) => { 
	const [openLib, setOpenLib] = useState(false);
	return ( 
		<nav> 
			<h1>Music Player</h1> 
			<button 
				onClick={() => { 
					setLibraryStatus(!libraryStatus); 
					if(openLib){
						setOpenLib(false)
					}
					else setOpenLib(true)
				}} 
			> 
				<h4>{openLib? 'Close' : 'Open'}</h4> 
			</button> 
		</nav> 
	); 
}; 

export default Nav; 

import { useEffect, useRef, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import { Link } from "react-router-dom";
import number_format from "../../utilities/number_format";
import Loading from "../../utilities/Loading";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel, InputAdornment, MenuItem, Select } from '@mui/material';
import TypewriterEffect from "../../utilities/typeWriterEffect";
import { ToastContainer, toast } from 'react-toastify';

const Introduction = () => {

  let chats = [
    {user: 'Maria', message: 'Kindly introduce yourself by telling me your First Name'},
    
  ]

  const [pageTile, setPageTitle] = useState("Introduction")
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [message, setMessage] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [errMessage, setErrMessage] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [chatList, setChatList] = useState(chats)

  const bottomRef = useRef(null);

    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status === 0){
          window.location.href = "/auth/login"
      }
      else {
        setIsLoggedIn(true)
      }
    }

    
   

      const sendMessage = () =>{
        let newMessage = {}
        newMessage = {user: "User", message: message}
        let newChatList = chatList.concat(newMessage);
        if(message !== null){
          setChatList(newChatList);
        }

        if(firstName === null && message === null){
          newMessage = {user: "Maria", message: 'Please enter your first name'}
          newChatList = chatList.concat(newMessage);
          setChatList(newChatList);
            }
            else if(firstName === null && message !== null){
            setFirstName(message)
            newMessage = [{user: "User", message: message}, {user: "Maria", message: `${message}, Please enter your Last Name`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
        setMessage('')

      }

          else if(lastName === null && message == null){
            newMessage = [{user: "User", message: message}, {user: "Maria", message: `${firstName}, Please enter your Last Name`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
            }
            
else if(lastName === null && message !== null){
  setLastName(message)
  newMessage = [{user: "User", message: message}, {user: "Maria", message: `${firstName} ${message}, Kindly confirm if your first and last name are correct. Type YES to confirm, else, type NO`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
        setMessage('')

      }
else if(firstName !== null && lastName !== null && message.trim().toUpperCase() === "YES"){
//update profile and redirect to home for questions
newMessage = [{user: "User", message: message}, {user: "Maria", message: `${firstName} ${lastName}, Once again, we are glad to have you. You will be redirected soon for a brief question and answer session. Kindly take your time to answer the questions correctly.`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
        setMessage('')

        //set timer before update and redirect
        setTimeout(()=>{
          updateProfile();
        }, 3000)
}
else{
  newMessage = [{user: "User", message: message}, {user: "Maria", message: `Type YES to proceed or refresh page to start all over again.`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
        setMessage('')
}

      }

      const QuestionMessageCard = (props) => {
        return <div className="row mb-2 justify-content-start align-items-start text-start">
          <div class="col-md-6 mt-2">
        <div class="card bg-primary">
            <div class="card-body">
              <div class="text-white">{props.message}
</div>
            </div>
            </div>
            </div>
        </div>
      }

      const QuestionMessageCardAnimation = (props) => {
        return <div className="row mb-2 justify-content-start align-items-start text-start">
          <div class="ccol-md-6 mt-2">
        <div class="card bg-primary">
            <div class="card-body">
              <div class="text-white"><TypewriterEffect text={props.message} delay={100} />
</div>
            </div>
            </div>
            </div>
        </div>
      }

      const ResponseMessageCard = (props) =>{
        return  <div className="row justify-content-end align-items-end text-end ml-auto align-self-end">
          <div class="col-md-6 mt-2 mb-2">
        <div class="card bg-white">
            <div class="card-body">
              <div class="text-primary">{props.message}</div>
            </div>
            </div>
          </div>
        </div>
      }
    
      const onSendMessageEnter = (event) =>{
        if(event.keyCode === 13){
            sendMessage()
        }
      }

      const updateProfile = async () => {
        setLoading(true)
        if(firstName != null && lastName != null){
        //setData({email: email, password: password})
        let x = await requestHandler.post('user/profile/update', {first_name: firstName, last_name: lastName}, false, true)
            if(x != null && x.status === 1){
                //let y = x.message;
                toast.success(x.message)
                window.location.href = "/"
            }
            else if(x != null && x.status === 0){
             let newMessage = {user: "Maria", message: x.message}
            let newChatList = chatList.concat(newMessage);
            setChatList(newChatList);
            }
            else{
            let newMessage = {user: "Maria", message: 'An error occurred, please try again later.'}
            let newChatList = chatList.concat(newMessage);
            setChatList(newChatList);
            }
        }
        setLoading(false)
    }
      
    useEffect(() => {
      //setFirstName(null)
      //setLastName(null)
      checkIfLoggedIn()
        document.title = pageTile
    },[chatList])

    useEffect(() => {
        //scroll to bottom every time chat changes
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, [chatList]);

    return(
      
      
        !isLoggedIn? <Loading />
        :
        <div>
        <div className="row">
        {isLoading? <Loading/> : ''}
 
        <QuestionMessageCard message={"Hi, I'm Maria. I'm indeed glad to have you."} />
        {/*<QuestionMessageCard message="" />*/}

        {chatList.map(x =>
          <div>
            {x.user === "Maria"? <QuestionMessageCard message={x.message} /> : ""}
          {x.user === "User"? <ResponseMessageCard message={x.message} /> : ""}
          </div>
        )}
      
                      

                      <div class="mt-5 mb-5 fixed-bottom">
                        <div className="m-5">
                      <TextField
                        type={'text'}
                        variant='outlined'
                        color='secondary'
                        label="Message"
                        onChange={e => setMessage(e.target.value)}
                        onKeyDown={onSendMessageEnter}
                        value={message}
                        fullWidth
                        style={{maxWidth: 770}}
                        sx={{mt: 4}}
                        InputProps={{endAdornment: (
                          <InputAdornment position="start">
                            {
                            isLoading? <Loading/> 
                            :
                            <div onClick={()=>sendMessage()}> 
                            <span className="m-3"></span>  <i className={message === null? "fa-regular fa-paper-plane text-center text-dark fa-2x" : "fa-regular fa-paper-plane text-primary text-center fa-2x"}></i>
                              </div>
                              }
                          </InputAdornment>
                      )}}                                          
                    />
                    </div>
                      </div>

{/*scroll to bottom*/}
                      <div ref={bottomRef} />

                </div>
                </div>
      

    )
}

export default Introduction;
const Images = () => {

    const robot = require('../images/3q63.gif');
    const bgImage = require('../images/hero.jpeg');
    const bgBlank = require('../images/blank_bg.jpeg');
    const profileImage = require('../images/profile.jpg');
    const landingImage = require('../images/landing.png');
    const musicBackground = require('../images/music_bg.jpeg');

    return {robot: robot, bgImage: bgImage, profileImage: profileImage, bgBlank: bgBlank, landingImage, musicBackground}
}

export default Images

import { Outlet } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";

const AuthLayout = () => {
return (
    <>
    {/*<Header />*/}
    <main>
    <section className="vh-100">
      <div className="container-fluid h-custom">
      

<Outlet />

    </div>
    </section>
    </main>
    <Footer/>
    </>
)
}
export default AuthLayout;
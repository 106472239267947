import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { Link, useNavigate } from "react-router-dom"
import requestHandler from "../../utilities/requestHandler"
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../../utilities/Loading"
import Images from "../../utilities/images"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel, InputAdornment, MenuItem, Select } from '@mui/material';
import countriesAndStates from "../../utilities/countries"
import RandomString from "../../utilities/randomString"

const Login = () => {

    const navigation = useNavigate();
    const [pageTile, setPageTitle] = useState("Account")
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [country, setCountry] = useState(null);
    const [states, setStates] = useState([]);
    const [state_, setState_] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [emailAvailable, setEmailAvailable] = useState(false)
    const [createNewAccount, setCreateNewAccount] = useState(false)

    const [fakeEmail, setFakeEmail] = useState(null);
    const [fakePassword, setFakePassword] = useState(null);

    //const countries = countriesAndDialCodes;
    const countries = countriesAndStates;

    const [loggedIn, setLoggedIn] = useState(false)
    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status === 1){
        //window.location.href = "/"
        setLoggedIn(true)
      }
  }
    
    const clearFileds = () => {
setEmail('')
setPassword('')
    }

    const setAccessDetails = () => {
      let loginId = localStorage.getItem('loginId');
      //let name = localStorage.getItem('l2');
      //if(loginId == null){
        loginId = RandomString(30);
        localStorage.setItem('loginId', loginId)
        setFakePassword(loginId)
     // }
      //setFakePassword(loginId)      
      setFakeEmail(`${loginId}@${loginId}.com`) 
    }

    useEffect(() => {
        document.title = pageTile
        setAccessDetails()
        checkIfLoggedIn()
    },[pageTile])

    const checkEmail = async () => {
        setLoading(true)
        //setData({email: email})
        if(email != null){
            
        let x = await requestHandler.post('auth/check/email', {email: email}, false, false)
        if(x != null && x.status === 1){
            //setEmailAvailable(true)
            loginAction();
        }
        else if(x != null && x.status === 0){
            toast.error(x.message)
        }
        else if(x != null && x.status === 2){
          setCreateNewAccount(true);
            /*let askToCreate = window.confirm("No account is associated with this email address. Click OK to create a new account.")
            if(askToCreate) setCreateNewAccount(true);
            else toast.error('Account not found') */
        }
        else{
            toast.error('Something went wrong')
        }
        }
        setLoading(false)
    }

    const checkStates = async (ctry) => {
      setLoading(true)
      setStates([])
      //setData({email: email})
      const st = countriesAndStates.filter(x => x.name === ctry);
      
      if(st.length > 0) {
        //console.log(st[0].states)
        setStates(st[0].states);
        setLoading(false)
    }
       /*   
      let x = await requestHandler.get(`settings/${ctry}/get_states_by_country`, false, false)
      if(x != null && x.status === 1){
        setState_(null);
          setStates(x.message)
          setLoading(false)
      }
      else if(x != null && x.status === 0){
          //toast.error(x.message)
          setState_("N/A");
          setLoading(false)
      }
      else{
          toast.error('Something went wrong')
          setLoading(false)
      } */
      
  }

    const loginAction = async () => {
        setLoading(true)
        if(fakePassword != null){
        //setData({email: email, password: password})
        let x = await requestHandler.post('auth/login_user', {email: fakeEmail, password: fakePassword}, false, false)
            if(x != null && x.status === 1){
              setLoading(false)
                //let y = x.message;
                //toast.success(x.message)
                //set login token
                localStorage.setItem('access_token', x.access_token)
                localStorage.setItem('account_type', x.account_type)
                //go back after log in
                window.location.href = "/"
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
                setLoading(false)
            }
            else{
                toast.error('Something went wrong')
                setLoading(false)
            }
        }
        else 
        {
            clearFileds()
            setLoading(false)
        }
        
    }

    const regAction = async () => {
      setLoading(true)
      //let Data = {username: username, email: email, referral: referral, password: password, password_confirmation: password_confirmation}
      let Data = {email: fakeEmail, password: fakePassword, password_confirmation: fakePassword, country: country}
      let x = await requestHandler.post('auth/register_new_user', Data, false, false)
      if(x != null && x.status === 1){
          //let y = x.message;
          toast.success(x.message)
          loginAction()
      }
      else if(x != null && x.status === 0)
      {
          toast.error(x.message)
      }
      else
      {
          toast.error('Something went wrong')
      }
      setLoading(false)
  }

    const onLoginEnter = (event) =>{
      if(event.keyCode === 13){
          loginAction()
      }
    }

    const logOut = async () => {
      let x = await requestHandler.get('auth/logout', true);
      if(x != null && x.status === 1){
        //do something after login
      }
      localStorage.removeItem('access_token')
        localStorage.removeItem('account_type')
        window.location.href = "/auth/login"
    }

    return(
     
      <div class="row justify-content-center align-items-center">
<div class="col-md-4 col-lg-4 col-xl-4">        
<form className=" tooltip-end-top" novalidate>
                              <div className="card-body">
                              <h1><strong>Welcome!</strong></h1>
                              {loggedIn? 
                              <div>
                                <p className="text-alternate mb-4">You have an active session. Kindly click the proceed button to continue or end session to end or start all over again.</p>
                                
                                <Link to="/" className="btn btn-icon btn-icon-end btn-primary">
                                  <span>Proceed</span>
                                    <i data-cs-icon="chevron-right"></i>
                                  </Link>
                                  <span className="m-2"></span>
                                  <button onClick={logOut} className="btn btn-icon btn-icon-end btn-danger">
                                  <span>End Session!</span>
                                  </button>
                                  </div>
                                  :
                                  '' }
                                {/*<div className="row">
                                <TextField
                        type="text"
                        variant='outlined'
                        color='secondary'
                        label="Email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        fullWidth
                        required
                        sx={{mb: 4}}
                    />
    </div> */}

                                {/* <div className="row" style={{display: emailAvailable? `flex` : `none`}}>
                                <TextField                                
                        type={showPassword? 'text' : 'password'}
                        variant='outlined'
                        color='secondary'
                        label="Password"
                        onChange={e => setPassword(e.target.value)}
                        //onKeyDown={e => onLoginEnter(e)}
                        value={password}
                        fullWidth
                        required
                        InputProps={{endAdornment: (
                          <InputAdornment position="end">
                            <div onClick={()=>{showPassword? setShowPassword(false) : setShowPassword(true)}}> 
                                 <i className={showPassword? "fa-regular fa-eye text-center fa-2x" : "fa-regular fa-eye-slash text-center fa-2x"}></i>
                                 </div>
                          </InputAdornment>
                      )}}
                                              
                    />
                                </div> */}

                        
                        {<div className="row" style={{display: !loggedIn? `flex` : `none`}}
                        >
<p className="text-alternate mb-4">Kindly select the country you are taking this test from.</p>
                                

  <TextField
  select
  value={country}
  color='secondary'
  label="Country"
  onChange={e => {setCountry(e.target.value); checkStates(e.target.value);}}
  fullWidth
    required 
    sx={{mb:4}}
>
{countries.map(x => <MenuItem value={x.name}>{x.name}</MenuItem>)}
</TextField>
{states.length > 0?
 <TextField
 select
 value={state_}
 color='secondary'
 label="Location"
 onChange={e => setState_(e.target.value)}
 fullWidth
   required 
   sx={{mb:4}}
>
{states.map(x => <MenuItem value={x.name}>{x.name}</MenuItem>)}
</TextField>

:
''
}

                        {/*  <TextField
                        type={'password'}
                        variant='outlined'
                        color='secondary'
                        label="Password"
                        onChange={e => setPassword(e.target.value)}
                        //onKeyDown={e => onLoginEnter(e)}
                        value={password}
                        fullWidth
                        required    
                        sx={{mb: 4}}                                          
                    />

                    <TextField
                        type={'password'}
                        variant='outlined'
                        color='secondary'
                        label="Confirm Password"
                        onChange={e => setPassword2(e.target.value)}
                        //={e => onLoginEnter(e)}
                        value={password2}
                        fullWidth
                        required    
                        sx={{mb: 4}}                                          
                    />*/}

{ isLoading? <Loading/> : 
                           country !== null && state_ !== null ? <button onClick={regAction} className="btn btn-icon btn-icon-end btn-primary" type="button">
                                    <span>Continue</span>
                                    <i data-cs-icon="chevron-right"></i>
                                </button> : <button disabled className="btn btn-icon btn-icon-end btn-primary" type="button">
                                    <span>Continue</span>
                                    <i data-cs-icon="chevron-right"></i>
                                </button> }
                        </div>
                         }
                                                            

                              </div>
                              <div className="border-0 pt-0 d-flex justify-content-between align-items-center">
                                {/*<div className="row">
                                <Link to="/auth/password/reset" className="me-3">Forgot Password?</Link>
                        </div>*/}
                                <div>
                                                                    {/*<button onClick={createNewAccount? regAction : emailAvailable? loginAction : checkEmail} className="btn btn-icon btn-icon-end btn-primary" type="button">*/}

                                  {/*isLoading? <Loading/> : 
                                  <button onClick={createNewAccount? regAction : emailAvailable? loginAction : checkEmail} className="btn btn-icon btn-icon-end btn-primary" type="button">
                                  <span>{createNewAccount? `Continue` : 'Proceed'}</span>
                                    <i data-cs-icon="chevron-right"></i>
                                  </button>*/}
                                  
                                </div>                      
                              </div>

                              {/*<div class="row">
                                <div className="input-group mt-3 filled">
                                <div style={{width: '10%'}} className="input-group-text"> 
                       <i className="fa-regular fa-g text-danger"></i>
                                  </div>
                                  <button style={{width: '90%'}} className="btn btn-block btn-danger btn-lg" type="button">
                                    Log In With Google
                                  </button>
                                </div>
                              </div>*/}
          
                              
                            </form>  
                           <div class="row d-flex justify-content-center align-items-center">
                    
                      </div> 
                </div>

                </div>
    )
}

export default Login;

import { useEffect, useState } from "react";
import Loading from "../../utilities/Loading";
import HomeContents from "./homeContents";
import PopularContent from "./popularContent";
import requestHandler from "../../utilities/requestHandler";
import UserOverview from "../user/userOverview";
import ContentsOverview from "../content/contentsOverview";
import Introduction from "../questions/Introduction";
import AskMariaView from "../questions/askMaria";
import Images from "../../utilities/images";
import TypewriterEffect from "../../utilities/typeWriterEffect";
import MusicPlayer from "../music_player/musicPlayer";

const Home = () => {

    const [announcement, setAnnouncement] = useState([])
    const [popUp, setPopUp] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [accountType, setAccountType] = useState(localStorage.getItem('account_type'))
    const [isIntroDone, setIsIntroDone] = useState(false)

    const checkIfLoggedIn = async () => {
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            //window.location.href = "/auth/login"
        }
        else {
          setIsLoggedIn(true)
        }
      }


      const checkIfIntroDone = async () => {
        let x = await requestHandler.get('user/profile', true);
        if(x != null && x.message && x.status === 1){
          let y = x.message[0];
            if(y.first_name !== null && y.last_name !== null){
              setIsIntroDone(true)
            }
        }
      }


    const closePopUp = () => {
        setPopUp(false)
    }

    useEffect (()=>{
        //checkIfLoggedIn()
        checkIfIntroDone()
    },[announcement])

    const PopUp = () => {
        return(
            <div class="modal fade modal-under-nav modal-search modal-close-out show" id="popUpModal" tabindex="-1" role="dialog" aria-modal="true" style={{display: 'block'}}>
                 <div class="fixed-background"></div>
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button type="button" class="btn-close btn btn-icon btn-icon-only btn-foreground" data-bs-dismiss="modal" aria-label="Close" onClick={closePopUp}></button>
          </div>
          <div class="modal-body ps-5 pe-5 pb-0 border-0">
            <h4>{announcement.subject}</h4>
          </div>
          <div class="modal-footer border-top justify-content-start ps-5 pe-5 pb-3 pt-3 border-0">
                    <div class="justify-content-start">
                        <div style={{whiteSpace: "pre-wrap"}} class="text-normal">{announcement.announcement} </div>
              </div>
            </div>

          </div>
        </div>
      </div>
        )
    }

    return (
        <div class="col">
           
            {/*popUp? <PopUp/> : ''*/}
            
            <div className="row p-3">
            {/*<div class="col-md-6 col-xl-8 col-xxl-9 mb-5"> */} 
            <div class="col-md-12 col-xl-8 col-xxl-8 mb-5">                
                {/*<HomeContents />*/}
                {isIntroDone? <AskMariaView/> : <Introduction />}                
            </div> 
            <div class="col-md-12 col-xl-4 col-xxl-4 d-none d-lg-block mb-5">  
            <div style={{top:30,
    position: `fixed`}} className="sticky-top">            
            <MusicPlayer/>
            </div>  
            </div>
            {/*<div class="col-md-6 col-xl-4 col-xxl-3 d-none d-md-block d-lg-block mb-5"> 
            <p><TypewriterEffect text={"Hi, I'm Maria, your virtual assistant. If you have any question other than the questions you have answered on this platform, kindly use the button below to send a message."} delay={100} /></p>
            <button className="btn btn-primary">Send a Message</button>
            <p></p>
            <p className="text-center"> <img src={Images().robot} height={"250"} /> </p>
            </div>*/}
            </div>
            </div>
    )
}

export default Home;
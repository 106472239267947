import { Outlet } from "react-router-dom";
import Header from "../header";
import LeftMenu from "../leftMenu";
import Footer from "../footer";
import { useEffect, useState } from "react";
import requestHandler from "../../utilities/requestHandler";

const MainLayout = () => {

  const account_type = localStorage.getItem("account_type")
  //const [maintain, setMaintain] = useState(0)
  const websiteSettings = async () => {
    let x = await requestHandler.get('settings/get', false);
    if(x != null && x.status === 1){
        let y = x.message;
        if(y.under_maintenance === 1 && account_type !== "Admin"){
          window.location.href="/auth/under-maintenance"
        }
    }
}

useEffect(() =>{
  websiteSettings()
}, [])

return (
    <>
{/*<Header /> */}
    <main>
      <div class="col">
        {/*<LeftMenu />*/}

<Outlet />

    </div>
    </main>
    <Footer/>
    </>
)
}
export default MainLayout;
// FileName: data.js 

import { v4 as uuidv4 } from "uuid"; 
import Images from "../../utilities/images";
function chillHop() { 
return [ 
	{ 
	name: "Sunrise Serenade", 
	cover: 
    Images().musicBackground, 
	artist: " Harmony Harp", 
	audio:  
"https://media.geeksforgeeks.org/wp-content/uploads/20231004185212/Jawan-Prevue-Theme.mp3", 
	color: ["#205950", "#2ab3bf"], 
	id: uuidv4(), 
	active: true, 
	}, 
	{ 
	name: "Urban Groove", 
	cover: 
    Images().musicBackground, 
	artist: "Beatmaster B", 
	audio: 
		"https://media.geeksforgeeks.org/wp-content/uploads/20231004184006/SoundHelix-Song-10.mp3", 
	color: ["#EF8EA9", "#ab417f"], 
	id: uuidv4(), 
	active: false, 
	}, 
	{ 
	name: "Mystic Echo", 
	cover: 
    Images().musicBackground, 
	artist: " Harmony Harp", 
	audio: 
"https://media.geeksforgeeks.org/wp-content/uploads/20231004185212/Jawan-Prevue-Theme.mp3", 
	color: ["#CD607D", "#c94043"], 
	id: uuidv4(), 
	active: false, 
	}, 
	{ 
	name: "Electro Vibes", 
	cover: 
    Images().musicBackground, 
	artist: "Synthwave Sensation", 
	audio: 
"https://media.geeksforgeeks.org/wp-content/uploads/20231004191840/Zinda-Banda---Jawan-(1).mp3", 
	color: ["#EF8EA9", "#ab417f"], 
	id: uuidv4(), 
	active: false, 
	}, 
	{ 
	name: "Jazzy Whispers", 
	cover: 
    Images().musicBackground, 
	artist: "Smooth Sax Serenade", 
	audio: 
"https://media.geeksforgeeks.org/wp-content/uploads/20231004184006/SoundHelix-Song-10.mp3", 
	color: ["#CD607D", "#c94043"], 
	id: uuidv4(), 
	active: false, 
	}, 
	{ 
	name: "Tropical Breez", 
	cover: 
    Images().musicBackground, 
	artist: "Island Rhythms", 
	audio: 
"https://media.geeksforgeeks.org/wp-content/uploads/20231004191840/Zinda-Banda---Jawan-(1).mp3", 
	color: ["#205950", "#2ab3bf"], 
	id: uuidv4(), 
	active: false, 
	}, 
]; 
} 

export default chillHop; 

import { useEffect, useRef, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import { Link } from "react-router-dom";
import number_format from "../../utilities/number_format";
import Loading from "../../utilities/Loading";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel, InputAdornment, MenuItem, Select } from '@mui/material';
import TypewriterEffect from "../../utilities/typeWriterEffect";
import { ToastContainer, toast } from 'react-toastify';
import truncate from "../../utilities/truncateString";


const AskMariaView = () => {

  let chats = [
    //{user: 'Maria', message: 'Kindly introduce yourself by telling me your First Name'},
    
  ]

  const [pageTile, setPageTitle] = useState("Ask Maria")
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [message, setMessage] = useState(null)
  const [question, setQuestion] = useState(null)
  const [questionID, setQuestionID] = useState(0)
  const [score_, setScore] = useState(0)
  const [questionOptions, setQuestionOptions] = useState([])
  const [questionAnswerNums, setQuestionAnswerNums] = useState(1)
  const [questionAnswerType, setQuestionAnswerType] = useState('Options')
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const [enterMessage, setEnterMessage] = useState(false)
  const [isMessageNumeric, setIsMessageNumeric] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [submitAnswer, setSubmitAnswer] = useState(false)
  const [response, setResponse] = useState(null)
  const [responseTitle, setResponseTitle] = useState(null)
  const [completedQuestion, setCompletedQuestion] = useState(false)
  const [mailSent, setMailSent] = useState(false)
  const [chatList, setChatList] = useState(chats)
  const [answersList, setAnswersList] = useState([])

  const bottomRef = useRef(null);

    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status == 0){
          window.location.href = "/auth/login"
      }
    }

    
    const sanitizeAnswer = async () => {
      setLoading(true)

      //getAnswers();

      let x = await requestHandler.get('questions/answers', true);
      let selectedAnswer = localStorage.getItem('selected_answers')
      
      if(selectedAnswer !== null)
      {
      selectedAnswer = JSON.parse(selectedAnswer)
      if(selectedAnswer.length > 0){
        if(x != null && x.status == 1)
      {        
        let y = x.message;
        selectedAnswer.concat(JSON.parse(y.answers));
      }

      }
      else {

        chatResponse('Maria', 'Please enter answer again')
      }
    }
      setLoading(false)
  }


  
      const updateAnswer = async () => {
      setLoading(true)

      //getAnswers();

      let x = await requestHandler.get('questions/answers', true);
      let selectedAnswer = localStorage.getItem('selected_answers')

      
      if(selectedAnswer !== null)
      {
      selectedAnswer = JSON.parse(selectedAnswer)
      if(selectedAnswer.length > 0){
        let newAnsList = []
        if(x != null && x.status == 1)
      {        
        let y = x.message;
        newAnsList = selectedAnswer.concat(JSON.parse(y.answers));
      }
      else
      {
        
        //alert(selectedAnswer)
        newAnsList = selectedAnswer
        //setAnswersList(selectedAnswer);
      }

        //setAnswersList(newAnsList);        

        //var task = window.confirm("Click OK to confirm your answer.")
        //if(task && selectedAnswer.length > 0) {
          if(selectedAnswer.length > 0) {
            //alert("hh")
            let x = await requestHandler.post('questions/answer/submit', {scores: 0, answers: JSON.stringify(newAnsList)}, false, true)
        if(x != null && x.status == 1){
           // setEmailAvailable(true)

           setSubmitAnswer(false)

           if(questionAnswerType !== "Options"){
            setMessage("")
            setEnterMessage(false)
           }
           getNextQuestion();
        }
        }else{
          chatResponse('Maria', "Answer not submitted. "+question)
        }

      }
      else {
        //alert("got here")
        chatResponse('Maria', 'Please enter answer again')
      }
    }

      //add old with new answers
        
      //let selectedAnswer = localStorage.getItem('selected_answers')
      
      setLoading(false)
  }
  

  /*
    const updateAnswer = async () => {
      setLoading(true)

      //getAnswers();

      let x = await requestHandler.get('questions/answers', true);
      let selectedAnswer = localStorage.getItem('selected_answers')      
      if(selectedAnswer !== null)
      {
      selectedAnswer = JSON.parse(selectedAnswer)
      if(selectedAnswer.length > 0){
        let newAnsList = []
        if(x != null && x.status == 1)
      {        
        let y = x.message;
        selectedAnswer.concat(JSON.parse(y.answers));
      }

      if(selectedAnswer.length > 0) {
        let x = await requestHandler.post('questions/answer/submit', {scores: 0, answers: JSON.stringify(newAnsList)}, false, true)
    if(x != null && x.status == 1){
       // setEmailAvailable(true)

       setSubmitAnswer(false)

       if(questionAnswerType !== "Options"){
        setMessage("")
        setEnterMessage(false)
       }
       getNextQuestion();
    }
    }else{
      chatResponse('Maria', "Answer not submitted. "+question)
    }
      
      }
    }
      setLoading(false)
  }
  */

    const getNextQuestion = async () => {
      //alert(questionAnswerType)
      let opt = "";
      setQuestion([])
      setQuestionOptions([])
      setLoading(true)
      let newMessage = {}
      let x = await requestHandler.get('questions/next', true);
      if(x != null && x.status == 1)
      {
        let y = x.question;
        newMessage = [{user: "Maria", message: y.question}]
        //let newChatList = chatList.concat(newMessage);
        let newChatList = newMessage
        if(y.id !== questionID)setChatList(newChatList);
        if(y.answer_nums > 1){
          opt = " Kindly note that you can select 1 to "+y.answer_nums+" answers for this question."
          newMessage = [{user: "Maria", message: y.question + opt}]
          setChatList(newMessage)
        }
        setQuestion(y.question)
        setQuestionID(parseInt(y.id))
        setScore(parseInt(y.score))
        
        setQuestionAnswerNums(y.answer_nums) 
        setQuestionAnswerType(y.answer_type)
        if(y.answer_type !== "Options"){
          setMessage("")
          setEnterMessage(true)
         }
         setQuestionOptions(x.options)
         /*else{
          if(y.id == questionID)setQuestionOptions(x.options)
         }*/
        setLoading(false)
      }
      if(x != null && x.status == 2){
        setCompletedQuestion(true)
        //chatResponse('Maria', 'You have completed your Question and Answer session. Below is your result')
        setResponse(x.response);
        setResponseTitle(x.title);
        setCaseNumber(x.case);
        setScores(x.score);
setLoading(false)
      }
      else if(x != null && x.status == 0){
        setChatList([])
        newMessage = {user: "Maria", message: x.message}
       /*
        let newChatList = chatList.concat(newMessage);
        setChatList(newChatList.filter(x => x.user == 'Maria' && x.message == message))
        */
        setChatList(newMessage)
        setLoading(false)
      }
      else{
        setLoading(false)
      }
    }

    const chatResponse = (name, message) => {
      let newMessage = {user: name, message: message} 
      const countAns = chatList.filter(x => x.user == name && x.message == message);
      //let newChatList = chatList.concat(newMessage);
      //  setChatList(newChatList);
      if(countAns.length < 1){
        let newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
      }
      else{
        setChatList(newMessage);
      }
    }

    const addAnswer = async (qId, qAns, sc) =>  {
      //alert(questionID)
      let selectedAnswer = localStorage.getItem('selected_answers')      
      let newQuestion = [{questionId: qId, question: question, answer: qAns, score: sc }]
        if(selectedAnswer !== null){
          
          selectedAnswer = JSON.parse(selectedAnswer)
        const countAns = selectedAnswer.filter(x => parseInt(x.questionId) == parseInt(qId) && x.answer == qAns);
        
        if(countAns.length < questionAnswerNums && questionAnswerNums > 1){
          
          //if(countAns.length +1 < questionAnswerNums && questionAnswerNums > 1){
           // alert("te")
          //alert(countAns.length)
          if(selectedAnswer.length > 0 && newQuestion != null){
           // alert("te2")
          let newSelectedAnswersList = selectedAnswer.concat(newQuestion);
          localStorage.setItem('selected_answers', JSON.stringify(newSelectedAnswersList))
        
          //remove duplicate repeated answer
          if(countAns.length > 0){

            const countAn = newSelectedAnswersList.filter(x => x.answer !== qAns);
            //remove if duplicate
            //localStorage.removeItem('selected_answers')
            localStorage.setItem('selected_answers', JSON.stringify(countAn))
             // getNextQuestion()
              if(countAn.length < 1){
                setSubmitAnswer(false);
              }
          }

        }
          
          else {
            //alert("er")
            localStorage.setItem('selected_answers', JSON.stringify(newQuestion))
          }
        }
        else if(countAns.length < 1){

          //let newSelectedAnswersList = selectedAnswer.concat(newQuestion);
          localStorage.setItem('selected_answers', JSON.stringify(newQuestion))
          //alert("ho")
        }

        /*if(countAns.length > 0){
          alert(answersList.length)
          //alert("tessss")
          const countAn = selectedAnswer.filter(x => x.questionId !== qId && x.answer !== qAns);
          alert(countAn.length)
          alert(JSON.stringify(countAn))
          //remove if duplicate
          localStorage.removeItem('selected_answers')
          localStorage.setItem('selected_answers', JSON.stringify(countAn))
           // getNextQuestion()
            if(countAn.length < 1){
              setSubmitAnswer(false);
            }
        }*/
        
        
        //else{
          
          //localStorage.removeItem('selected_answers')
        //}
        /*else {
          const newAns = selectedAnswer.filter(x => x.questionId !== qId && x.answer !== qAns);
          localStorage.setItem('selected_answers', JSON.stringify(newAns))
        }*/
      }
      else{
        localStorage.setItem('selected_answers', JSON.stringify(newQuestion))
      }
      
      /*else{

      }*/
        
        //chatResponse('User', qAns)

      //console.log(selectedAnswers)
      }

      const removeAnswer = async (qId, qAns) =>  {
        let selectedAnswer = localStorage.getItem('selected_answers')
        
       // let newQuestion = [{questionId: qId, question: question, answer: qAns, score: 0 }]
          if(selectedAnswer !== null){
            
            selectedAnswer = JSON.parse(selectedAnswer)
          const countAns = selectedAnswer.filter(x => x.answer !== qAns);
         
          if(countAns && countAns.length > 0){
            //let newSelectedAnswersList = countAns;
            localStorage.removeItem('selected_answers')
            localStorage.setItem('selected_answers', JSON.stringify(countAns))
            //let newSelectedAnswersList = selectedAnswer.concat(newQuestion);
            //localStorage.setItem('selected_answers', JSON.stringify(newQuestion))
        
          }
        }
        
        }

    function selectAnswer(questionId, answer, score) {
      addAnswer(questionId, answer, score)
      
      let selectedAnswer = localStorage.getItem('selected_answers')
      
        if(questionAnswerType == "Options"){
        //if(questionAnswerNums )
        if(selectedAnswer !== null){
          selectedAnswer = JSON.parse(selectedAnswer)
        const countAns = selectedAnswer.filter(x => parseInt(x.questionId) == parseInt(questionId));
        if(countAns && questionAnswerNums > 0 && countAns.length > questionAnswerNums){
          
          //remove question and tell user they can not answer more for the question
          
          let clearAnswer = window.confirm(`Sorry, you can not pick more than ${questionAnswerNums} answers. Click OK to select answers again`)
            if(clearAnswer){
              let filterAns = selectedAnswer.filter(x => parseInt(x.questionId) !== parseInt(questionId) && x.answer !== answer)
              localStorage.setItem('selected_answers', JSON.stringify(filterAns))     
              setSubmitAnswer(false); 
            } 
            else{
              removeAnswer(questionId, answer)
              //sanitizeAnswer()
            } 
//          chatResponse('Maria', `Sorry, you can not pick more than ${questionAnswerNums} answers`)
//chatResponse('Maria', 'Sorry, you cannot select more answers for this question. To select new answer, deselect already selected answer')
//sanitizeAnswer()   
  
}
        else {
          const countAns = selectedAnswer.filter(x => parseInt(x.questionId) == parseInt(questionId))
       
          if(countAns && countAns.length < questionAnswerNums && countAns.length > 0){
       
            //remove answer if selected more than once
            //updateAnswer()
            
            sanitizeAnswer()
            setSubmitAnswer(true)
          }
          else if(countAns && countAns.length == questionAnswerNums){
        
            //remove answer if selected more than once
            //updateAnswer()
            
            sanitizeAnswer()
            setSubmitAnswer(true)
          }
          else if(countAns && countAns.length > questionAnswerNums){
            //remove answer if selected more than once
            //updateAnswer()
            //sanitizeAnswer()
            //setSubmitAnswer(true)
          }
          else{

            //sanitizeAnswer()
            //addAnswer(questionId, answer, score)
          //chatResponse('Maria', 'Sorry, I could not submit your answer, please try again')
          }          
        } 

        }
        else {
          addAnswer(questionId, answer, score)
          //updateAnswer()
          sanitizeAnswer()
          setSubmitAnswer(true)
        }
               
      }
      else if(questionAnswerType == "Number"){
       // chatResponse('User', message)
setIsMessageNumeric(true)
setEnterMessage(true)
if(message == null || message.trim() == ""){
  setMessage("")
  chatResponse('Maria', "Kindly enter numeric characters")
}
else if(!window.Number.isInteger(parseFloat(message))){
  setMessage("")
  chatResponse('Maria', "You entered an invalid number")
}
else {

  let filterAns = [{questionId: questionId, question: question, answer: answer, score: 0 }]
  localStorage.setItem('selected_answers', JSON.stringify(filterAns))
  //alert(filterAns)
  addAnswer(questionId, answer, score)
  sanitizeAnswer()
  updateAnswer()
}
      }

      else if(questionAnswerType == "Text"){
        // chatResponse('User', message)
 setIsMessageNumeric(false)
 setEnterMessage(true)
 if(message == null || message.trim() == ""){
   setMessage("")
   chatResponse('Maria', "Kindly enter data before you submit")
 }
 /*
 else if(!window.Number.isInteger(parseFloat(message))){
   setMessage("")
   chatResponse('Maria', "You entered an invalid number")
 }
 */
 else {
   
  let filterAns = [{questionId: questionId, question: question, answer: answer, score: 0 }]
   localStorage.setItem('selected_answers', JSON.stringify(filterAns))
   addAnswer(questionId, answer, score)
   sanitizeAnswer()
   updateAnswer()
 }
       }

    }

    const AnswersButtons = (props) => {
      try{
        let selectedAnswer = localStorage.getItem('selected_answers')
        selectedAnswer = JSON.parse(selectedAnswer)
      const countAns = selectedAnswer.filter(x => parseInt(x.questionId) == parseInt(props.question_id) && x.answer == props.answer);
      return <div className="row mb-2 justify-content-start align-items-start text-start">
      <div class="col-md-6 mt-2">
    <button onClick={() => selectAnswer(props.question_id, props.answer, props.score)} className={countAns.length > 0? "btn btn-dark text-lg" : "btn btn-info text-lg"}>{props.answer}</button>
        </div>
    </div>
      }
      catch{
        return <div className="row mb-2 justify-content-start align-items-start text-start">
        <div class="col-md-6 mt-2">
      <button onClick={() => selectAnswer(props.question_id, props.answer, props.score)} className={"btn btn-info text-lg"}>{props.answer}</button>
          </div>
      </div>
      }
      
    }
   

      const sendMessage = () =>{
        let newMessage = {}
        newMessage = {user: "User", message: message}
        let newChatList = chatList.concat(newMessage);
        if(message !== null){
          setChatList(newChatList);
        }

        if(firstName === null && message === null){
          newMessage = {user: "Maria", message: 'Please enter your first name'}
          newChatList = chatList.concat(newMessage);
          setChatList(newChatList);
            }
            else if(firstName === null && message !== null){
            setFirstName(message)
            newMessage = [{user: "User", message: message}, {user: "Maria", message: `${message}, Please enter your Last Name`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
        setMessage('')

      }

          else if(lastName === null && message === null){
            newMessage = [{user: "User", message: message}, {user: "Maria", message: `${firstName}, Please enter your Last Name`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
            }
            
else if(lastName === null && message !== null){
  setLastName(message)
  newMessage = [{user: "User", message: message}, {user: "Maria", message: `${firstName} ${message}, Kindly confirm if your first and last name are correct. Type YES to confirm, else, type NO`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
        setMessage('')

      }
else if(firstName !== null && lastName !== null && message.trim().toUpperCase() === "YES"){
//update profile and redirect to home for questions
newMessage = [{user: "User", message: message}, {user: "Maria", message: `${firstName} ${lastName}, Once again, we are glad to have you. You will be redirected soon for a brief question and answer session. Kindly take your time to answer the questions correctly.`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
        setMessage('')

        //set timer before update and redirect
        setTimeout(()=>{
          updateProfile();
        }, 3000)
}
else{
  newMessage = [{user: "User", message: message}, {user: "Maria", message: `Type YES to proceed or refresh page to start all over again.`}]
        newChatList = chatList.concat(newMessage);
        setChatList(newChatList);
        setMessage('')
}

      }

      const QuestionIntroCard = (props) => {
        return <div className="row mb-2 justify-content-start align-items-start text-start">
          <div class="col-md-6 mt-2">
        <div class="card bg-light">
            <div class="card-body">
              <div class="text-dark"><h4>{props.message}</h4>
</div>
            </div>
            </div>
              
            </div>
            </div>
      }

      const QuestionMessageCard = (props) => {
        //alert(props.id)
        return <div className="row mb-2 justify-content-start align-items-start text-start">
         {/* <div class="col-md-6 mt-2"> */}
         <div class="col">
        <div class="card bg-primary">
            <div class="card-body">
              <div class="text-white"><h5 class="text-white">{props.message}</h5>
</div>
            </div>
            </div>
            {questionOptions.map((x) =>
            <div>
            {props.id == parseInt(x.question_id)? <AnswersButtons answer={x.answer} question_id={parseInt(x.question_id)} score={x.score} /> : "" }
            </div>
              )}
              
            </div>
            </div>
      }

      const QuestionMessageCardAnimation = (props) => {
        return <div className="row mb-2 justify-content-start align-items-start text-start">
          <div class="col-md-6 mt-2">
        <div class="card bg-primary">
            <div class="card-body">
              <div class="text-white"><TypewriterEffect text={props.message} delay={10} />
</div>
            </div>
            </div>

            {questionOptions.map((x) =>
            <div>
            {props.id == parseInt(x.question_id)? <AnswersButtons answer={x.answer} question_id={parseInt(x.question_id)} score={x.score} /> : "" }
            </div>
              )}
              
            </div>
        </div>
      }

      const ResponseMessageCard = (props) =>{
        return  <div className="row justify-content-end align-items-end text-end ml-auto align-self-end">
          <div class="col-md-6 mt-2 mb-2">
        <div class="card bg-white">
            <div class="card-body">
              <div class="text-primary">{props.message}</div>
            </div>
            </div>
          </div>
        </div>
      }
    
      const onSendMessageEnter = (event) =>{
        if(event.keyCode === 13){
            //sendMessage()
            selectAnswer(questionID, message, score_)
        }
      }

      const updateProfile = async () => {
        setLoading(true)
        if(firstName != null && lastName != null){
        //setData({email: email, password: password})
        let x = await requestHandler.post('user/profile/update', {first_name: firstName, last_name: lastName}, false, true)
            if(x != null && x.status == 1){
                //let y = x.message;
                toast.success(x.message)
                window.location.href = "/"
            }
            else if(x != null && x.status == 0){
             let newMessage = {user: "Maria", message: x.message}
            let newChatList = chatList.concat(newMessage);
            setChatList(newChatList);
            }
            else{
            let newMessage = {user: "Maria", message: 'An error occurred, please try again later.'}
            let newChatList = chatList.concat(newMessage);
            setChatList(newChatList);
            }
        }
        setLoading(false)
    }
      
    useEffect(() => {
      getNextQuestion()
      //localStorage.removeItem('selected_answers')
    },[])

    useEffect(() => {
      checkIfLoggedIn()
        document.title = pageTile
    },[])

    useEffect(() => {
      //alert(isMessageNumeric);
        //scroll to bottom every time chat changes
        //bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, [chatList, selectedAnswers, answersList, questionOptions]);

      const [email, setEmail] = useState(null);
      const [phone, setPhone] = useState(null);
      const [caseNumber, setCaseNumber] = useState(null);
      const [scores, setScores] = useState(null);
      const sendResultAction = async () => {
        setLoading(true)
        if(email != null){
        //setData({email: email, password: password})
        let x = await requestHandler.post('questions/send_result', {email: email, phone: phone, score: scores, case_number: caseNumber}, false, true);
            if(x != null && x.status == 1){
                toast.success(x.message)
                setMailSent(true)
                //chatResponse('Maria', "You will receive your result in few minutes. Thanks for using Ask Maria.")
                setLoading(false)
                          }
                          else if(x != null && x.status == 0){
                            toast.error(x.message)
                            setLoading(false)
                        }
                        else if(x != null && x.message !== null){
                          toast.error(x.message)
                          setLoading(false)
                      }
                                  else{
                toast.error('Something went wrong')
                setLoading(false)
            }
        }
        
    }

    const AboutAskMariah = () =>{
      alert("Ask Mariah is an AI virtual assistant that gives counselling...");
    }

    const logOut = async () => {
      let x = await requestHandler.get('auth/logout', true);
      if(x != null && x.status == 1){
        //do something after login
      }
      localStorage.removeItem('access_token')
        localStorage.removeItem('account_type')
        window.location.href = "/auth/login"
    }

    return(
      <div>
      <div className="row">
      
        {/*isLoading? <Loading/> : ''*/}
        <div className="bg-white justify-content-center d-flex align-items-end text-end ml-auto align-self-end fixed-top">
        <button onClick={AboutAskMariah} className="btn btn-md btn-info mt-2 mb-2">About</button>
        <div className="m-2"></div>
        <button onClick={logOut} className="btn btn-md btn-danger mt-2 mb-2">End Session</button>
 </div>
        {!completedQuestion? <QuestionIntroCard message="Welcome back! Kindly click or enter your response as prompted." /> : ''}
        
        {!completedQuestion && chatList? chatList.map(x =>
          <div>
            { x.user == "Maria"? <><QuestionMessageCard message={x.message} id={questionID} /> </> : "" }
            {/*x.user == "Maria"? <><QuestionMessageCardAnimation message={x.message} id={questionID} /> </> : "" */}
          {x.user == "User"? <ResponseMessageCard message={x.message} /> : ""}
          </div>
        ) : ''}
<div className="col">

{completedQuestion? <div>
  <div className="row justify-content-start align-items-start text-start ml-auto align-self-start">
          <div class="col mt-2 mb-2">
        <div class="card bg-primary">
            <div class="card-body">
              <div class="text-white">You have completed your Question and Answer session. Enter your email to get full result in your inbox.</div>
            </div>
            </div>
          </div>
        </div>
   <h3><b>Result:</b> {responseTitle}</h3> </div> : ''}
{completedQuestion? <div>{truncate(response == null? '' : response, 150)}</div> : ''}
        
        <div className="row" style={{display: completedQuestion && !mailSent? `flex` : `none`}}>
        
                                <TextField
                                style={{width: `97%`}}
                        type="text"
                        variant='outlined'
                        color='secondary'
                        label="Email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        fullWidth
                        required
                        sx={{mb: 2, mt: 4}}
                    />

<TextField
                                style={{width: `97%`}}
                        type="text"
                        variant='outlined'
                        color='secondary'
                        label="Phone (Optional)"
                        onChange={e => setPhone(e.target.value)}
                        value={phone}
                        fullWidth
                        sx={{mb: 4}}
                    />

<div className="row">
{isLoading? '' : 
                                  <button onClick={sendResultAction} className="btn btn-primary" type="button">
                                  Send
                                  </button>}
</div>
                                </div>

                                <div className="row" style={{display: completedQuestion && mailSent? `flex` : `none`}}>
<div className="alert alert-success mt-2"> We have sent a mail to {email}. You will receive mail in few minutes. If you did not received mail within 10 minutes, kindly check your spam/junk box. </div>
                                </div>
                                
                                </div>

        <div ref={bottomRef} />
      {/*<AnswersButtons question="Yes" />*/}
                      
     
                      

{/*scroll to bottom*/}
                      

                </div>

<div className="row mb-2 justify-content-start align-items-end text-end">
      <div class="col-md-6 mt-2">
        <div className="row">
    {isLoading? <Loading/> 
    :
    <button style={{display: submitAnswer? 'block' : 'none'}} onClick={() => updateAnswer()} className={"btn btn-block btn-lg btn-primary text-lg"}>{"Next  "}<i className="fa fa-chevron-right"></i> </button>
    }
       </div>
        </div>
    </div>

        <div style={{display: enterMessage? 'block' : 'none'}} class="mt-5 mb-5 fixed-bottom">
                        <div className="m-5">
                      <TextField
                    style={{maxWidth: 770}}
                        type={isMessageNumeric? 'number' : 'text'}
                        
                        variant='outlined'
                        color='secondary'
                        label="Enter"
                        onChange={e => setMessage(e.target.value)}
                        onKeyDown={onSendMessageEnter}
                        value={message}
                        fullWidth
                        sx={{mt: 4}}
                        InputProps={{endAdornment: (
                          <InputAdornment position="start">
                            {
                            isLoading? <Loading/> 
                            :
                            <div onClick={()=> selectAnswer(questionID, message, score_)}> 
                            <span className="m-3"></span>  <i className={message == null? "fa-regular fa-paper-plane text-center text-dark fa-2x" : "fa-regular fa-paper-plane text-primary text-center fa-2x"}></i>
                              </div>
                              }
                          </InputAdornment>
                      )}}                                          
                    />
                    </div>
                      </div>
       

    </div>
    )
}

export default AskMariaView;